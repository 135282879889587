import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Team from "../components/team"

const TeamPage = () => (
  <Layout pageInfo={{ pageName: "team" }}>
    <SEO title="Team" />
    <h1>Team</h1>
    <Team />
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default TeamPage
