import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from "gatsby-image"
import { Card, Button, Container, Row, Col} from "react-bootstrap"
// import styled from 'styled-components'


const Team = () => {
    const data = useStaticQuery(graphql`
      query TeamComponentQuery {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(team)/"  }}) {
            edges {
                node {
                frontmatter {
                    name
                    title
                    image {
                        id
                        childImageSharp {
                          fluid(maxWidth: 600, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                    rawMarkdownBody
                }
            }
        }
      }
    `)
  
    const teamData = data.allMarkdownRemark.edges
    return (
        <div>
            <Container>
              <Row>
                {teamData.map(({ node }) => {
                    return (
                        <Col md={3} key={node.frontmatter.name}>
                          <Card className='mb-4'>
                              {node.frontmatter.image ? <Img fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.name} /> : ''}
                              <Card.Body>
                              <h4>{node.frontmatter.name}</h4>
                              <h5>{node.frontmatter.title}</h5>
                              {node.rawMarkdownBody}
                              </Card.Body>
                          </Card>
                        </Col>
                    )
                })}
              </Row>
            </Container>
        </div>
    )
  }
  
  export default Team